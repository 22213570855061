export class LoadBroadcastChannelsAction {
  static readonly type = '[BroadcastChannels] Load Broadcast Channels';
}

export class LoadSlackChannelsAction {
  static readonly type = '[BroadcastChannels] Load Slack Channels';
}

export class CreateBroadcastChannelAction {
  static readonly type = '[BroadcastChannels] Create Broadcast Channel';
  constructor(
    public iql: string,
    public providerProfileId: string,
    public recipientEndpointId: string
  ) {}
}

export class UpdateBroadcastChannelAction {
  static readonly type = '[BroadcastChannels] Update Broadcast Channel';
  constructor(
    public id: string,
    public iql: string,
    public providerProfileId: string,
    public recipientEndpointId: string
  ) {}
}

export class DeleteBroadcastChannelAction {
  static readonly type = '[BroadcastChannels] Delete Broadcast Channel';
  constructor(public id: string) {}
}

export class OpenBroadcastChannelDialogAction {
  static readonly type = '[BroadcastChannels] Open Broadcast Channel Dialog';
  constructor(public broadcastChannelId?: string) {}
}

export class CloseBroadcastChannelDialogAction {
  static readonly type = '[BroadcastChannels] Close Broadcast Channel Dialog';
}
