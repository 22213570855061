import { Component, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { RequiredFeatureAnnotation } from 'src/app/core/guards/features.guard';
import { RequiredRolesAnnotation } from 'src/app/core/guards/permission.guard';
import { AuthorizedMenuItem } from 'src/app/models/permission';
import { MenuService } from 'src/app/services/menu/menu.service';

@Component({
  selector: 'app-page-settings-shell',
  templateUrl: 'settings.component.html',
  styleUrls: ['settings.component.scss'],
})
export class SettingsShellPageComponent {
  private store = inject(Store);

  items: AuthorizedMenuItem[] = [
    {
      label: 'pages.settings.menu.account',
      items: [
        {
          label: 'pages.settings.menu.general',
          icon: 'pi pi-user',
          routerLink: ['/settings/account/general'],
        },
        {
          label: 'pages.settings.menu.twofa',
          icon: 'pi pi-lock',
          routerLink: ['/settings/account/two-factor'],
        },
        {
          label: 'pages.settings.menu.webauthn',
          icon: 'pi pi-lock',
          routerLink: ['/settings/account/webauthn'],
        },
        {
          label: 'pages.settings.menu.notifications',
          icon: 'pi pi-bell',
          routerLink: ['/settings/account/notifications'],
        },
      ],
    },
    {
      label: 'pages.settings.menu.incidents',
      items: [
        {
          label: 'pages.settings.menu.incidentSchemas',
          icon: 'pi pi-copy',
          routerLink: ['/settings/incidents/schemas'],
          [RequiredRolesAnnotation]: ['incidentschemas.manager'],
          [RequiredFeatureAnnotation]: ['incident-schema-editor'],
        },
        {
          label: 'pages.settings.menu.incidentRoles',
          icon: 'pi pi-th-large',
          routerLink: ['/settings/incidents/roles'],
          [RequiredRolesAnnotation]: ['incidentroles.manager'],
        },
        {
          label: 'pages.settings.menu.alerting',
          icon: 'pi pi-microchip',
          routerLink: ['/settings/alerting'],
          [RequiredRolesAnnotation]: ['alerts.manager'],
          [RequiredFeatureAnnotation]: ['enable-alerting'],
        },
      ],
    },
    {
      label: 'pages.settings.menu.communication',
      items: [
        {
          label: 'pages.settings.menu.providers',
          icon: 'pi pi-bell',
          routerLink: ['/settings/communication/providers'],
          [RequiredRolesAnnotation]: ['notifications.manager'],
        },
        {
          label: 'pages.settings.menu.broadcastChannels',
          icon: 'pi pi-megaphone',
          routerLink: ['/settings/incidents/broadcast-channels'],
          [RequiredRolesAnnotation]: ['broadcastchannels.manager'],
        },
      ],
    },
    {
      label: 'pages.settings.menu.organization',
      items: [
        {
          label: 'pages.settings.menu.organizationMembers',
          icon: 'pi pi-users',
          routerLink: ['/settings/organization/members'],
          [RequiredRolesAnnotation]: ['organization.manager'],
        },
        {
          label: 'pages.settings.menu.subscription',
          icon: 'pi pi-bolt',
          routerLink: ['/settings/organization/subscription'],
          [RequiredRolesAnnotation]: ['subscriptions.manager'],
        },
        {
          label: 'pages.settings.menu.billing',
          icon: 'pi pi-money-bill',
          routerLink: ['/settings/organization/billing'],
          [RequiredRolesAnnotation]: ['billing.reader'],
        },
      ],
    },
  ];

  items$ = this.store.select(MenuService.filteredMenuItems(this.items));
}
