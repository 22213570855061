import { Component, OnInit, inject } from '@angular/core';
import { LoginFlow, UpdateLoginFlowWithPasswordMethod } from '@ory/client';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { KratosAuthenticationService } from 'src/app/services/authentication/kratos.service';

@Component({
  selector: 'app-dialog-reauthenticate',
  templateUrl: './reauthenticate.component.html',
})
export class ReAuthenticateDialogComponent implements OnInit {
  private kratos = inject(KratosAuthenticationService);
  loginFlow?: LoginFlow;

  useAal2 = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.kratos.whoami().then(session => {
      // check if user has an aal2 method configures
      const aal2Method = session.authentication_methods?.find(method => {
        return method.aal === 'aal2';
      });

      if (aal2Method) {
        this.useAal2 = true;
      }

      this.kratos.createLoginFlow(aal2Method ? 'aal2' : 'aal1').then(flow => {
        this.loginFlow = flow;
      });
    });
  }

  submitFlow(body: UpdateLoginFlowWithPasswordMethod) {
    if (!this.loginFlow) return;
    this.kratos
      .completeLoginFlowV2(this.loginFlow, body.method, {
        ...body,
        method: 'password',
      })
      .then(() => {
        this.onLoggedIn();
      })
      .catch(err => {
        this.loginFlow = err.response.data;
      });
  }

  onLoggedIn() {
    this.ref.close();
  }
}
